import React from 'react';

import './style.css';

function Footer() {

    return (
        <footer>
            <div>
                <h3>Primi Tecnologia Ltda.</h3>
            </div>
        </footer>
    )

}

export default Footer;