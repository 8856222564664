import React from 'react';

const ListVariables = React.forwardRef(({ templateVariable, errors }, ref) => {

    return (
        <>
            {templateVariable.length > 0 ? (<h3 id='title-variables'>Variáveis</h3>) : ''}

            {templateVariable.map(variable => (
                <div key={variable} className='form-div'>

                    <p>{variable}:</p>
                    <input type='text' name={`variables.${variable}`} ref={ref} placeholder={`${variable} *`} maxLength='100' />
                    {errors.variables && (
                        <p className='error-input'>{errors.variables[variable].message}</p>
                    )}
                </div>
            ))}
        </>
    )
})

export default ListVariables;