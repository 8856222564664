import React from 'react';
import { Link } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion'
import { OnCreate } from 'components/Animation'

import SuccessGif from '../../assets/confirm.gif'

import './style.css'

const Sent = () => {

    return (
        <AnimatePresence>

            <OnCreate>
                <main id="send-success">

                    <div id='container-message'>

                        <div className='img-success'>

                            <img src={SuccessGif} alt='Gif do sucesso' />

                        </div>

                        <h3>Os e-mails foram adicionados para serem enviados!</h3>
                        <p>Ao completar todos os envios será gerado um relatório e será encaminhado para o e-mail informado.</p>

                        <Link to={'/'}>
                            <button className='btn-send-home'>Voltar para a início!</button>
                        </Link>

                    </div>

                </main>
            </OnCreate>

        </AnimatePresence>
    )

}

export default Sent;