import React, { useState } from 'react';
import ReactTooltip from 'react-tooltip';

import { FaInfoCircle } from 'react-icons/fa'

import { maskTimeDelay, maskNumber } from 'utils/mask'

const FormDelay = React.forwardRef(({ errors }, ref) => {

    const [isDelayMinutes, setIsDelayMinutes] = useState(true);

    const handleLimit = e => {
        const value = parseFloat(e.target.value);
        value >= 1 ? setIsDelayMinutes(false) : setIsDelayMinutes(true);
    }

    const filterNumber = e => {
        e.target.value = maskNumber(e.target.value);
    }

    const filterTime = e => {
        e.target.value = maskTimeDelay(e.target.value);
    }

    return (
        <>
            <div className='email-div'>

                <div className='form-div-sub'>

                    <p>
                        Delay inicial: <span className='info-tooltip' data-tip data-for='delay_init'>< FaInfoCircle /></span>
                    </p>
                    <input type='text' name='delay_init' onKeyUp={filterNumber} onBlur={filterTime} ref={ref} placeholder='Atraso para começar' maxLength='4' />
                    {errors.delay_init && (
                        <p className='error-input'>{errors.delay_init.message}</p>
                    )}

                    <ReactTooltip id='delay_init' place='right' className='tooltipEffect' effect='solid'>
                        <h3>Atraso ao Iniciar</h3>
                        <p>Para programar um intervalo antes de iniciar, digite-o em minutos. Ex: 1h = 60</p>
                    </ReactTooltip>

                </div>

                <div className='form-div-sub'>

                    <p>
                        Limite:  <span className='info-tooltip' data-tip data-for='limit'>< FaInfoCircle /></span>
                    </p>
                    <input type='text' name='limit' onKeyUp={filterNumber} onChange={handleLimit} ref={ref} placeholder='Limite de e-mails por atraso' maxLength='4' />
                    {errors.limit && (
                        <p className='error-input'>{errors.limit.message}</p>
                    )}

                    <ReactTooltip id='limit' place='right' className='tooltipEffect' effect='solid'>
                        <h3>Limite de e-mails</h3>
                        <p>Será limitado o envio de e-mails a cada intervalo definido no campo "Intervalo de Envio".</p>
                    </ReactTooltip>

                </div>
                <div className='form-div-sub'>

                    <p>
                        Intervalo de Envio: <span className='info-tooltip' data-tip data-for='delay'>< FaInfoCircle /></span>
                    </p>
                    <input type='text' name='delay_minutes' onKeyUp={filterNumber} ref={ref} disabled={isDelayMinutes} onBlur={filterTime} placeholder='Enviar o tempo em minutos' maxLength='4' />
                    {errors.delay_minutes && (
                        <p className='error-input'>{errors.delay_minutes.message}</p>
                    )}

                    <ReactTooltip id='delay' place='right' className='tooltipEffect' effect='solid'>
                        <h3>Intervalo de Envio</h3>
                        <p>Para programar um intervalo de tempo ao enviar os e-mails, digite-o em minutos. Ex: 1h = 60</p>
                    </ReactTooltip>

                </div>
            </div>

            <div className='form-div'>

                <p>
                    E-mail para envio de relatório: <span className='info-tooltip' data-tip data-for='email_report'>< FaInfoCircle /></span>
                </p>
                <input type='email' name='email_report' ref={ref} placeholder='Digite um e-mail *' maxLength='100' />
                {errors.email_report && (
                    <p className='error-input'>{errors.email_report.message}</p>
                )}

                <ReactTooltip id='email_report' place='right' className='tooltipEffect' effect='solid'>
                    <h3>E-mail para relatório</h3>
                    <p>Após o término de envio dos e-mails será encaminhado um relatório para este e-mail.</p>
                    <p>É obrigatório o uso do domínio @primi.com.br</p>
                </ReactTooltip>

            </div>

        </>
    );

});

export default FormDelay;