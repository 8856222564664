import Toastify from 'toastify-js';
import "toastify-js/src/toastify.css"

export const validationVariables = (arrayVariables, json) => {
    let valid = [];

    if (arrayVariables.length > 0) {

        json.map(prop => {

            if (prop.variables) {
                const indexJson = Object.entries(prop.variables);

                if (indexJson.length === arrayVariables.length) {

                    arrayVariables.map(variable => {

                        if (prop.variables.hasOwnProperty(variable)) {
                            valid.push(true);
                        } else {
                            valid.push(false);
                        }

                        return null;
                    })

                } else {
                    valid.push(false);
                }
            } else {
                valid.push(false);
            }

            return null;
        });
    }

    if (valid.indexOf(false) === -1) {
        return true;
    } else {
        return false;
    };

}

export const buildContextSend = (data, context) => {

    let json = {};

    const to = JSON.stringify(context.to[0]);

    json.to = to;

    if (data.attachments.length === 0) {
        delete data.attachments;
    }

    json = { ...json, ...data }
    json = { ...json, ...context.template[0] };

    let formData = new FormData()

    for (var property in json) {
        if (property === 'attachments') {

            for (let i = 0; i < json.attachments.length; i++) {
                formData.append(property, json.attachments[i])
            }

        } else if (property === 'template') {

            for (let i = 0; i < json.template.length; i++) {
                formData.append(property, json.template[i])
            }

        } else {
            formData.append(property, json[property])
        }

    }
    return formData;
}

export const toast = (text, success) => {
    let color;

    if(success){
        color = 'linear-gradient(to right, #00b09b, #96c93d)';
    } else {
        color = 'linear-gradient(to right, #b01b07, #d6230b)';
    }

    Toastify({
        text: text,
        duration: 5000,
        gravity: "bottom",
        position: 'right',
        backgroundColor: color,
        stopOnFocus: true,
        className: "info-notify",
    }).showToast();
};

export const setDefaultValue = data => {

    if(data.delay_init === ''){
        data.delay_init = '0';
    } else {
        const delay_init = data.delay_init.split(' ');
        data.delay_init = delay_init[0];
    }

    if(data.limit === ''){
        data.limit = '0';
    }else {
        const limit = data.limit.split(' ');
        data.limit = limit[0];
    }

    if(data.delay_minutes === ''){
        data.delay_minutes = '0';
    }else {

        if(data.limit === '0'){
            data.delay_minutes = '0';
        } else {
            const delay_minutes = data.delay_minutes.split(' ');
            data.delay_minutes = delay_minutes[0];
        }
    }

    return data;
}