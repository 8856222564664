import React, { useState, useEffect } from 'react';
import { FaFileCsv, FaFileSignature, FaFileCode } from "react-icons/fa";
import ReactTooltip from 'react-tooltip';
import csv from 'csvtojson';
import { validationVariables, toast } from 'utils/index'

import { useDispatch, useSelector } from 'react-redux';
import { addToAction } from 'action'
import Modal from './Modal'

import api from 'services/api'

import './style.css'

const OptionsToEmail = () => {

    const [newEmail, setNewEmail] = useState([]);
    const [modalIsOpen, setIsOpen] = React.useState(false);
    const [templateVariable, setTemplateVariable] = useState([]);

    const dispatch = useDispatch();
    const templateContext = useSelector(state => state.template);
    const variablesContext = useSelector(state => state.data);

    useEffect(() => {

        dispatch(addToAction(newEmail));

    }, [dispatch, newEmail]);

    useEffect(() => {

        api.get('/templates')
            .then(res => {
                const data = res.data;

                if (templateContext) {
                    data.payload.map(template => {

                        if (template.name === templateContext[0].name) {
                            const variablesArray = template.variables.split(',');

                            if (variablesArray[0] !== "") {
                                setTemplateVariable(variablesArray);
                            }
                        }
                        return null
                    });
                }
            })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {

        if (variablesContext[0]) {
            setTemplateVariable(variablesContext[0]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [templateVariable])

    const openModal = () => {
        setIsOpen(true);
    }

    const closeModal = () => {
        setIsOpen(false);
    }

    const readJsonFile = (e) => {

        if (e.target.files[0]) {
            const jsonFile = e.target.files[0];
            const ext = jsonFile.name.split('.').pop();

            if (ext === 'json') {
                const fileReader = new FileReader();

                fileReader.onload = (event) => {

                    try {
                        const json = JSON.parse(event.target.result);

                        if (validationVariables(templateVariable, json)) {
                            setNewEmail([...newEmail, ...json]);
                        } else {
                            toast(`As variáveis enviadas não correspondem a do template. Este template possui: ${templateVariable.toString()}`, false);
                        }

                    } catch (error) {
                        toast(`O json não corresponde aos padrões!`, false);
                    }
                }

                fileReader.readAsText(jsonFile);

            } else {
                toast(`Selecione um arquivo com a extensão ".json"`);
                e.target.files = undefined;
            }
        }
    }

    const readCsvFile = (e) => {

        if (e.target.files[0]) {
            const csvFile = e.target.files[0];
            const ext = csvFile.name.split('.').pop();

            if (ext === 'csv') {

                const reader = new FileReader();
                reader.onload = (event) => {

                    csv()
                        .fromString(event.target.result)
                        .then((jsonObj) => {

                            const arrayJson = [];

                            jsonObj.forEach((data) => {
                                const json = {
                                    email: '',
                                    variables: {}
                                }

                                json.email = `${data.email}`;
                                delete data.email;

                                json.variables = { ...data };
                                arrayJson.push(json);
                            })

                            if (validationVariables(templateVariable, arrayJson)) {
                                setNewEmail([...newEmail, ...arrayJson]);
                            } else {
                                toast(`As variáveis enviadas não correspondem a do template. Este template possui: ${templateVariable.toString()}`, false);
                            }
                        })
                };

                reader.readAsText(e.target.files[0]);
            } else {
                toast(`Selecione um arquivo com a extensão ".csv"`, false);
                e.target.files = undefined;
            }
        }
    }


    return (
        <>
            <div className='btn-to-email-box'>

                <div data-tip data-for='email' onClick={openModal} className='btn-to-email'><FaFileSignature /></div>
                <label data-tip data-for='json' className='btn-to-email'>
                    <FaFileCode />
                    <input
                        type='file'
                        name='to_json'
                        accept=".json"
                        data-cy="file-upload"
                        id='uploadJson'
                        onChange={readJsonFile}
                    />
                </label>
                <label data-tip data-for='csv' className='btn-to-email'>
                    <FaFileCsv />
                    <input
                        type='file'
                        name='to_csv'
                        accept=".csv"
                        data-cy="file-upload"
                        id='uploadCsv'
                        onChange={readCsvFile}
                    />
                </label>


                <ReactTooltip id='email' place='bottom' className='tooltipEffect' effect='solid'>
                    <h3>E-mails</h3>
                    <p>Digite aqui os e-mails!</p>
                </ReactTooltip>

                <ReactTooltip id='json' place='bottom' className='tooltipEffect' effect='solid'>
                    <h3>Json</h3>
                    <p>Importe um arquivo <strong>.json</strong> com os e-mails!</p>
                </ReactTooltip>

                <ReactTooltip id='csv' place='bottom' className='tooltipEffect' effect='solid'>
                    <h3>CSV</h3>
                    <p>Importe um arquivo <strong>.csv</strong> com os e-mails!</p>
                </ReactTooltip>


            </div>

            {newEmail.length > 0 ? <span id='info-emails'>Foram encontrados: {newEmail.length} e-mails.</span> : ''}

            <Modal
                newEmail={newEmail}
                handleEmail={setNewEmail}
                templateVariable={templateVariable}
                closeModal={closeModal}
                handleModal={modalIsOpen} />
        </>
    )
};

export default OptionsToEmail;