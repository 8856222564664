import React, { useState } from 'react';
import { AnimatePresence } from 'framer-motion'

import FormTemplate from './FormTemplate';
import FormData from './FormData';

import { OnCreate } from '../Animation/index';

import './style.css'


function Body() {

    const [isTemplateBox, setTemplateBox] = useState(true);

    const handleTemplateChange = (boolean) => {
        setTemplateBox(boolean);
    }

    return (
        <div className="content-all">

            {isTemplateBox ? (
                <>
                    <AnimatePresence >
                        <OnCreate>

                            <FormTemplate handleTemplate={handleTemplateChange} />

                        </OnCreate>

                    </AnimatePresence>
                </>
            ) : (
                    <>

                        <OnCreate>

                            <FormData handleTemplate={handleTemplateChange} />

                        </OnCreate>

                    </>
                )}
                
        </div>
    )
}

export default Body;