import React from 'react';
import OptionsToEmail from '../OptionsToEmail';
import UploadAttachments from '../UploadAttachments';
import api from 'services/api'

import { createBrowserHistory } from 'history'
import { dataSchema as validationSchema } from 'utils/validation'

import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { addDataAction } from "action"
import { buildContextSend, toast, setDefaultValue } from 'utils/index'

import FormDelay from './FormDelay'
import FormEmail from './FormEmail'

const FormDataEmail = ({ handleTemplate }) => {

    const { handleSubmit, register, errors } = useForm({
        validationSchema
    });

    const dispatch = useDispatch();
    const context = useSelector(state => state);
    const history = createBrowserHistory();

    let formData;

    const sendEmail = (formData) => {
        const config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        };

        api.post('/mail', formData, config)
            .then(result => {
                history.push('/sent');
                history.go(0);
            })
            .catch(erro => {
                toast(erro, false);
            })

    }

    const onSubmit = (data) => {

        if (data.email) {
            data.from = `${data.name} <${data.email}>`;

            delete data.name;
            delete data.email;
        }

        const newData = setDefaultValue(data);

        if (context.to[0].length !== 0) {

            dispatch(addDataAction(newData));
            formData = buildContextSend(newData, context);
            sendEmail(formData);

        } else {
            toast("Adicione um e-mail para ser enviado", false);
        }
    }

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <h2>E-mail</h2>

                <div className='template-box'>
                    <div className='email-box'>

                        <FormEmail ref={register} errors={errors}/>
                        <FormDelay ref={register} errors={errors}/>

                    </div>

                    <div className='attachments-box'>
                        <div className='form-attach'>

                            <p>Anexos:</p>
                            <UploadAttachments ref={register} errors={errors} />

                        </div>
                    </div>
                </div>

            </form>

            <div className='template-box' id='to-div'>

                <div className='email-box'>
                    <div className='form-div'>
                        <p>Para (Selecione uma opção abaixo):</p>

                        <OptionsToEmail />

                    </div>
                </div>

                <div className='attachments-box'></div>

            </div>

            <div className='btn-next-box'>
                <input className='btn-next' value='Voltar' type='button' onClick={() => handleTemplate(true)} />
                <input className='btn-next' value='Enviar E-mail' type='button' onClick={handleSubmit(onSubmit)} />
            </div>

        </>
    )
}

export default FormDataEmail;