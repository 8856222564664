import React from 'react';
import ReactTooltip from 'react-tooltip';

import { FaInfoCircle } from 'react-icons/fa'

const FormEmail = React.forwardRef(({errors}, ref) => {

    return (
        <>
            <div className='email-div'>
                <div className='form-div-sub'>

                    <p>Nome (Remetente):</p>
                    <input type='text' name='name' ref={ref} placeholder='Digite o nome *' maxLength='100' />
                    {errors.name && (
                        <p className='error-input'>{errors.name.message}</p>
                    )}

                </div>
                <div className='form-div-sub'>

                    <p>E-mail (Remetente): <span className='info-tooltip' data-tip data-for='dominio'>< FaInfoCircle /></span> </p>
                    <input type='text' name='email' ref={ref} placeholder='Digite o e-mail *' maxLength='100' />
                    {errors.email && (
                        <p className='error-input'>{errors.email.message}</p>
                    )}

                    <ReactTooltip id='dominio' place='right' className='tooltipEffect' effect='solid'>
                        <h3>Domínio</h3>
                        <p>É necessário enviar um e-mail com o domínio válido.</p>
                    </ReactTooltip>

                </div>
            </div>

            <div className='form-div'>

                <p>Assunto:</p>
                <input type='text' name='subject' ref={ref} placeholder='Digite um assunto *' maxLength='100' />
                {errors.subject && (
                    <p className='error-input'>{errors.subject.message}</p>
                )}

            </div>

        </>
    );

});

export default FormEmail;