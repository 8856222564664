import React, { useState } from 'react';
import { MdDeleteForever, MdModeEdit } from "react-icons/md";

const ListEmail = ({ newEmail, deleteItemList, editItemList }) => {

    let [code] = useState(0);

    return (
        newEmail.map(email => (
            <div key={code++} className='item-list-emails'>
                {email.email}

                <div>
                    <MdModeEdit
                        id='list-email-edit'
                        onClick={() => editItemList(email.email)} />

                    <MdDeleteForever
                        id='list-email-delete'
                        onClick={() => deleteItemList(email.email)} />
                </div>
            </div>
        ))
    )
}

export default ListEmail;