import React from 'react';

import './style.css'

const ConfirmDelete = ({ deleteVisible, handleEmail, newEmail, emailDelete, setDeleteVisible }) => {

    const deleteItemList = () => {
        const data = newEmail.filter(item => item.email !== emailDelete);
        handleEmail(data);
        setDeleteVisible(false)
    }

    return (

        deleteVisible && (
            <div className='container-confirm-delete'>

                <div className='box-confirm-delete'>

                    <h3>Confirma a exclusão desse email?</h3>

                    <div>
                        <input className='btn-next' value='Cancelar' onClick={() => setDeleteVisible(false)} type='button' />
                        <input className='btn-next' value='Sim' onClick={deleteItemList} type='button' />
                    </div>

                </div>
            </div>
        ))

}

export default ConfirmDelete;