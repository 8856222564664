import React from "react";
import { motion } from "framer-motion";


export const OnCreate = ({ children }) => (
    <motion.div
        className='templates-content'
        initial={{ opacity: 0, x: "-100%" }}
        animate={{ opacity: 1, x: 0 }}
        exit={{ opacity: 0, x: "-100%" }}
        transition={{
            type: "spring",
            stiffness: 260,
            damping: 20
        }}
    >
        {children}
    </motion.div>
);
