import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { AnimatePresence } from 'framer-motion'
import api from 'services/api'
import { TiArrowBack } from 'react-icons/ti'

import './style.css'
import { OnCreate } from 'components/Animation'

const Template = ({ match }) => {

    const { name } = match.params;

    const [templateRender, setTemplateRender] = useState('');

    useEffect(() => {

        api.get(`/templates/render/${name}`)
            .then(result => {
                setTemplateRender(result.data)
            })
            .catch(err => {
                console.log(err);
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    return (
        <AnimatePresence>
            <OnCreate>

                <main id='template-render'>
                    <div className='content-template'>

                        <h2>
                            <Link to='/'><TiArrowBack id='back-home' /></Link>
                            {name}
                        </h2>

                        <div id='template-box-render' dangerouslySetInnerHTML={
                            {
                                __html: templateRender
                            }
                        }>
                        </div>

                    </div>
                </main>

            </OnCreate>
        </AnimatePresence>
    )
}

export default Template;