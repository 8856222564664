import React, { useState } from 'react';
import { MdAttachFile } from 'react-icons/md';
import { toast } from 'utils/index';

import './style.css';

const UploadAttachments = React.forwardRef((props, ref) => {

    const [isFileSelected, setIsFileSelected] = useState(false);
    const [fileName, setFileName] = useState([]);

    const handleAttachmentsChange = (e) => {
        const files = Array.from(e.target.files);

        if (files.length >= 1) {
            const names = []

            files.map(file =>
                names.push(file.name)
            );

            setFileName(names)
            setIsFileSelected(true);

            toast('Anexos selecionados', true);

        } else {
            setFileName('');
            setIsFileSelected(false);
        }
    }

    return (
        <>
            <div className='box-attachments'>
                <div className='div-attachments' >

                    <label htmlFor='uploadFile' className='upload-content-attach'>
                        <div className="hover-icon"><MdAttachFile /></div>

                        <input
                            type='file'
                            name='attachments'
                            data-cy="file-upload"
                            id='uploadFile'
                            multiple
                            ref={ref}
                            onChange={handleAttachmentsChange} />
                    </label>

                </div>

                <div className='div-attachments' >
                    <ul>
                        {isFileSelected ?
                            fileName.map(file => (
                                <li key={file}>{file}</li>
                            ))
                            : (
                                <li>Nenhum arquivo selecionado</li>
                            )}

                    </ul>
                </div>
            </div>
        </>
    );

})

export default UploadAttachments;