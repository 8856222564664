

export const maskTimeDelay = value => {

    if (value !== '') {
        return value === '1' ?
            value.replace(/(.*)/, "$1 minuto") :
            value.replace(/(.*)/, "$1 minutos")
    }
    return '';
}

export const maskNumber = value => value.replace(/[^0-9]/g, '');