import React from 'react';

import { BrowserRouter, Switch, Route } from 'react-router-dom';

import Main from './pages/main';
import Template from './pages/template'
import Sent from './pages/sent'

const Routes = () => (
    <BrowserRouter>
        <Switch>
            <Route exact path="/" component={Main} ></Route>
            <Route exact path="/template/:name" component={Template}></Route>
            <Route exact path="/sent" component={Sent}></Route>
        </Switch>
    </BrowserRouter>
);

export default Routes;