import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import TemplateUpload from '../UploadTemplate';
import LisTemplate from '../ListTemplate';

import { useDispatch, useSelector } from 'react-redux';
import { addTemplateAction } from 'action';

import { toast } from 'utils/index';

const FormTemplate = ({ handleTemplate }) => {

    const [isChecked, setIsChecked] = useState(false);
    const [isChange, setIsChange] = useState(false)

    const { handleSubmit, register } = useForm();

    const dispatch = useDispatch();
    const template = useSelector(state => state.template);

    useEffect( () => {
        setIsChange(false);
        setIsChecked(false)
    }, [])

    const handleCheckChange = () => {
        setIsChecked(true);
        setIsChange(false)
    }

    const onChangeTemplate = () => {
        setIsChange(true)
        setIsChecked(false);
    }

    const onSubmit = (data) => {

        if (isChange) {
            data.name = data.templates_name;

            delete data.templates_name;
            delete data.template;

            dispatch(addTemplateAction(data));

            handleTemplate(false);

        } else if (isChecked) {

            delete data.templates_name;
            dispatch(addTemplateAction(data));
            handleTemplate(false);

        } else if (template) {

            handleTemplate(false);

        } else {
            toast('Nenhum template selecionado', false);
        }

    }

    return (

        <form onSubmit={handleSubmit(onSubmit)}>

            <h2>Template</h2>

            <div className='templates-options'>

                <p>Selecione um template (corpo do e-mail) ou faça upload para prosseguir:</p>

                <div className="template-box">

                    <LisTemplate check={isChecked} handleChange={onChangeTemplate} ref={register} />

                    <TemplateUpload change={isChange} onClick={handleCheckChange} ref={register} />


                </div>
            </div>

            <div className='btn-next-box'>
                <p></p>
                <input className='btn-next' value='Próximo' type='submit' onClick={handleSubmit} />
            </div>

        </form>
    )

}

export default FormTemplate;