import React from 'react';

import logoPrimi from '../../assets/logoPrimi.png';
import './style.css';

function Header() {
    return (
        <header>
            <div>
                <h1>Mailer</h1>
                <img src={logoPrimi} alt='Logotipo da empresa Primi' />
            </div>
        </header>
    );
}

export default Header;

