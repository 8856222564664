export const addToAction = (to) => {
    return { type: 'ADD_TO', to };
}

export const addTemplateAction = (template) => {
    return { type: 'ADD_TEMPLATE', template };
}

export const addDataAction = (data) => {
    return { type: 'ADD_DATA', data };
}

