import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import ListVariables from './ListVariables'
import ListEmail from './ListEmail'
import { modalSchema as validationSchema } from 'utils/validation'
import ConfirmDelete from './ConfirmDelete'

import './style.css'

const ModalBody = ({ newEmail, handleEmail, templateVariable }) => {

    const { handleSubmit, register, errors, reset, setValue } = useForm({ validationSchema });

    const [titleButton, setTitleButton] = useState('Adicionar');
    const [deleteVisible, setDeleteVisible] = useState(false);
    const [emailDelete, setEmailDelete] = useState();

    const deleteItemList = (email) => {
        setEmailDelete(email);
        setDeleteVisible(true);
    }

    const editItemList = (email) => {
        const data = newEmail.filter(item => item.email === email)

        const dataMain = data[0].email.split('<');
        setTitleButton('Editar');

        setValue('name', dataMain[0].trim());
        setValue('email', dataMain[1].substring(dataMain[1].indexOf("<") + 1, dataMain[1].indexOf(">")));

        if (data[0].variables) {
            Object.keys(data[0].variables).forEach(function (item) {
                setValue(`variables.${item}`, data[0].variables[item]);
            });
        }

        const dataDelete = newEmail.filter(item => item.email !== email);
        handleEmail(dataDelete);
    }

    const onSubmit = (data) => {

        if (titleButton === 'Editar') {
            setTitleButton('Adicionar');
        }

        data.email = `${data.name} <${data.email}>`;

        delete data.name;
        handleEmail([...newEmail, data]);

        reset({
            name: '',
            email: '',
        })
    }

    return (
        <div className='body-modal'>

           <ConfirmDelete deleteVisible={deleteVisible} handleEmail={handleEmail} setDeleteVisible={setDeleteVisible} newEmail={newEmail} emailDelete={emailDelete}/>

            <div className='email-div'>
                <div className='div-form-to'>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className='form-div'>

                            <p>Nome:</p>
                            <input type='text' name='name' ref={register} placeholder='Digite o nome *' maxLength='100' />
                            {errors.name && (
                                <p className='error-input'>{errors.name.message}</p>
                            )}

                        </div>
                        <div className='form-div'>

                            <p>E-mail:</p>
                            <input type='text' name='email' ref={register} placeholder='Digite o e-mail *' maxLength='100' />
                            {errors.email && (
                                <p className='error-input'>{errors.email.message}</p>
                            )}

                        </div>

                        <hr></hr>

                        <ListVariables ref={register} templateVariable={templateVariable} errors={errors} />

                        <div className='btn-next-box'>
                            <p></p>
                            <input className='btn-next' value={titleButton} onClick={handleSubmit} type='submit' />
                        </div>

                    </form>

                </div>
                <div className='form-div-list-emails'>

                    <p>Lista de e-mails:</p>

                    <div className='list-emails'>

                        <ListEmail newEmail={newEmail} deleteItemList={deleteItemList} editItemList={editItemList} />

                    </div>
                </div>
            </div>
        </div>
    )
}

export default ModalBody;