import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { addDataAction } from 'action/index'
import { Link } from 'react-router-dom'

import api from 'services/api'

import './style.css'

const ListTemplate = React.forwardRef(({ check, handleChange }, ref) => {
    const [templates, setTemplates] = useState([]);
    const [isCheck, setIsCheck] = useState();

    const dataContext = useDispatch();
    const templatesContext = useSelector(state => state.template);

    useEffect(() => {
        if (check) {
            setIsCheck(0);
        }
    }, [check])

    useEffect(() => {
        api.get('/templates')
            .then(res => {

                if (res.data.success === true) {
                    if (res.data.payload.length > 0) {

                        const result = res.data.payload.filter(template => template.name !== 'mailer.hbs')
                        setTemplates(result);

                    } else {
                        setTemplates([]);
                    }
                }

            }).catch(error => {
                console.log(error);
            })
    }, [])

    useEffect(() => {

        if (templatesContext[0]) {
            const name = templatesContext[0].name;

            templates.map(template => {
                if (template.name === name) {
                    setIsCheck(template.id);
                }
                return null;
            })
        }

    }, [templates, templatesContext])

    const radioChange = (e) => {
        handleChange();

        const id = parseInt(e.target.id);
        setIsCheck(id);

        dataContext(addDataAction(null))
    }

    const checkStyle = {
        opacity: "1",
        visibility: "visible",
        animation: "checkIcon 0.2s",
        animationFillMode: "both"
    };

    const backCheckStyle = {
        opacity: "1",
        visibility: "visible",
        animation: "BackCheckIcon 0.2s",
        animationFillMode: "both"
    };

    return (

        <>
            {templates.map(template => (
                <label key={template.id} htmlFor={template.id} className="template" >

                    <input
                        type='radio'
                        ref={ref}
                        checked={template.id === isCheck}
                        className='template-radio'
                        name='templates_name'
                        onChange={radioChange}
                        value={template.name}
                        id={template.id} />


                    <div className='container-template-box'>
                        <div className='template-img'>
                            <img src={`https://api.mailer.primi.com.br/asset/${template.name}.png`} alt='Imagem do template' />

                            <div className='template-img-sup'>

                                <Link to={`/template/${template.name}`}>
                                    <button className='btn-img-view'>Visualizar</button>
                                </Link>

                            </div>
                        </div>
                        <p>
                            {template.name}
                        </p>
                    </div>

                    <div className='check-content'>

                        {isCheck === template.id ? (
                            <div style={backCheckStyle} className="check-button">

                                <div style={checkStyle} id='check-on'></div>

                            </div>
                        ) : (
                                ''
                            )}

                    </div>
                </label >
            ))}
        </>

    )

});

export default ListTemplate;
