import * as yup from 'yup'

export const modalSchema = yup.object().shape({
    name: yup
        .string()
        .required('O nome é obrigatório'),
    email: yup
        .string()
        .email('O e-mail digitado é inválido').required('O e-mail é obrigatório'),
    variables: yup
        .lazy(value => {

            if (!value) {
                return yup.mixed().notRequired();
            }

            const keys = Object.keys(value);

            const jsonValidation = {};

            keys.map(key => {
                jsonValidation[key] = yup.string().required('Este campo é obrigatório');
                return null;
            });

            return yup.object().shape(
                jsonValidation
            );

        }),
})

export const dataSchema = yup.object().shape({
    name: yup
        .string()
        .required('O nome é obrigatório'),
    email: yup
        .string()
        .email('O e-mail digitado é inválido')
        .required('O e-mail é obrigatório'),
    subject: yup
        .string()
        .required('O assunto é obrigatório'),
    limit: yup
        .string('Somente números'),
    delay_minutes: yup
        .string('Somente números'),
    delay_init: yup
        .string('Somente números'),
    email_report: yup
        .string()
        .email('O e-mail digitado é inválido')
        .matches(/\w+@primi\.com\.br/, 'É necessário o domínio "@primi.com.br"')
        .required("O e-mail é obrigatório")
})