import React from 'react'
import { FaFileSignature } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";
import ModalBody from './ModalBody'
import Modal from 'react-modal';

import './style.css'

Modal.setAppElement(document.getElementById('modal'));

const ModalEmail = ({ newEmail, handleEmail, templateVariable, handleModal, closeModal }) => {

    return (
        <Modal
            isOpen={handleModal}
            onRequestClose={closeModal}
            className='modal-box'
        >

            <main className='modal-container'>

                <div className='header-modal'>

                    <h2><FaFileSignature /> E-mails</h2>

                    <IoMdClose onClick={closeModal} className='btn-close' />

                </div>

                <ModalBody newEmail={newEmail} handleEmail={handleEmail} templateVariable={templateVariable} />

                <div className='footer-modal'></div>
            </main>
        </Modal>
    )
}

export default ModalEmail;