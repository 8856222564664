import React from 'react';

import './style.css'
import Body from '../../components/Body';

import { Provider } from 'react-redux'
import store from '../../store/index'

function Main() {

    return (
        <div className='body-content'>
            <Provider store={store}>
                <Body />
            </Provider>
        </div>
    )

}

export default Main;