import React, { useState, useEffect } from 'react';
import { FaFileCode } from 'react-icons/fa';
import { MdAttachFile } from 'react-icons/md';
import { addDataAction } from 'action/index';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'utils/index'

import './style.css';

const Email = React.forwardRef(({ onClick, change }, ref) => {

    const [isFileSelected, setIsFileSelected] = useState(false);
    const [fileName, setFileName] = useState('');

    const dispatch = useDispatch();

    const templates = useSelector(state => state.template)

    useEffect(() => {
        if (change) {
            setFileName('');
            setIsFileSelected(false);
        }
    }, [change])

    useEffect(() => {
        if (templates && !change) {

           if (!templates[0]?.name) {
                setFileName(templates[0]?.template[0].name);
                setIsFileSelected(true);

                onClick();
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [templates]);

    const readTemplateFile = (fileUpload) => {
        const fileReader = new FileReader();

        fileReader.onload = (event) => {

            const fileData = event.target.result;
            getVariables(fileData);

        }

        fileReader.readAsText(fileUpload);
    }

    const getVariables = (file) => {

        const regex = /\{\{ ?[\w_]+ ?\}\}/gi;

        const variables = file.match(regex);

        const arrayVariables = [];

        if (variables) {
            variables.map(variable => {
                arrayVariables.push(variable.substring(variable.indexOf("{{") + 2, variable.indexOf("}}")).trim());
                return null;
            })

            dispatch(addDataAction(arrayVariables));
            return;
        }

        dispatch(addDataAction([]))
    }

    const handleTemplateChange = async (e) => {
        const fileUpload = e.target.files[0];

        if (fileUpload) {
            const ext = fileUpload.name.split('.').pop();

            if(ext === 'hbs'){
                setFileName(fileUpload.name);
                setIsFileSelected(true);
    
                readTemplateFile(fileUpload);
    
                toast('Template selecionado com sucesso', true);
                onClick();

            } else {
                e.target.files = undefined;
                toast("Selecione um arquivo com a extensão '.hbs'", false);
            }

        } else {
            setFileName('');
            setIsFileSelected(false);
        }
    }

    return (
        <label htmlFor='uploadTemplate' className="template">

            {isFileSelected ? (
                <div style={{ boxShadow: '0.8px 0.8px 10px rgba(46, 95, 132, 0.5)' }} className='upload-content-template'>
                    <div className="hover-icon"><FaFileCode /></div>
                    <p>{fileName}</p>
                </div>
            ) :
                (
                    <div className='upload-content-template'>
                        <div className="hover-icon"><MdAttachFile /></div>
                        <div id="btn-upload">Escolha seu template!</div>
                    </div>
                )}

            <p id='extensions-info'>Extensões válidas:  *.hbs</p>

            <input
                type='file'
                name='template'
                accept=".hbs"
                data-cy="file-upload"
                id='uploadTemplate'
                ref={ref}
                onChange={handleTemplateChange} />

        </label>
    )
})

export default Email;