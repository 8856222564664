import { createStore } from 'redux';

const INITIAL_STATE = {
    to: [],
    template: '',
    data: [],
};

const emails = (state = INITIAL_STATE, action) => {
    
    switch (action.type) {

        case 'ADD_TO':
            return { ...state, to: [action.to] };

        case 'ADD_TEMPLATE':
            return { ...state, template: [action.template] };

        case 'ADD_DATA':
            return { ...state, data: [action.data] };

        default:
            return state;
    }
}

const store = createStore(emails);

export default store;